import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";

class FilterMenu extends Component {
    state = {
        filterText: "",
        filterRangeFrom: "",
        filterRangeTo: "",
        filterBy: "entry"
    };

    onChange = e => {
        const filterProperties = this.state;
        const { filter } = this.props;

        this.setState({
            [e.target.name]: e.target.value
        });

        filterProperties[e.target.name] = e.target.value;
        filter(filterProperties);
    };

    render() {
        const {
            filterText,
            filterBy,
            filterRangeFrom,
            filterRangeTo
        } = this.state;
        return (
            <div className="card mt-3">
                <div className="card-header">Filter</div>
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label htmlFor="filterBy">Filter by</label>
                            <br />
                            <div className="btn-group btn-group-toggle">
                                <label
                                    className={classnames(
                                        "btn",
                                        "btn-secondary",
                                        {
                                            active: filterBy === "entry"
                                        }
                                    )}
                                >
                                    <input
                                        type="radio"
                                        name="filterBy"
                                        id="filterBy0"
                                        value="entry"
                                        onChange={this.onChange}
                                        checked={filterBy === this.value}
                                    />{" "}
                                    Entry
                                </label>
                                <label
                                    className={classnames(
                                        "btn",
                                        "btn-secondary",
                                        {
                                            active: filterBy === "category"
                                        }
                                    )}
                                >
                                    <input
                                        type="radio"
                                        name="filterBy"
                                        id="filterBy1"
                                        value="category"
                                        onChange={this.onChange}
                                        checked={filterBy === this.value}
                                    />{" "}
                                    Category
                                </label>
                                <label
                                    className={classnames(
                                        "btn",
                                        "btn-secondary",
                                        "disabled",
                                        {
                                            active: filterBy === "value"
                                        }
                                    )}
                                >
                                    <input
                                        type="radio"
                                        name="filterBy"
                                        id="filterBy2"
                                        value="value"
                                        onChange={this.onChange}
                                        checked={filterBy === this.value}
                                        disabled
                                    />{" "}
                                    Value
                                </label>
                                <label
                                    className={classnames(
                                        "btn",
                                        "btn-secondary",
                                        "disabled",
                                        {
                                            active: filterBy === "date"
                                        }
                                    )}
                                >
                                    <input
                                        type="radio"
                                        name="filterBy"
                                        id="filterBy3"
                                        value="date"
                                        onChange={this.onChange}
                                        checked={filterBy === this.value}
                                        disabled
                                    />{" "}
                                    Date
                                </label>
                            </div>
                        </div>
                        {filterBy === "value" ? (
                            <div className="form-group">
                                <label htmlFor="filterText">
                                    Filter {filterBy}
                                </label>
                                <div className="row">
                                    <div className="col">
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="filterRangeFrom"
                                            onChange={this.onChange}
                                            value={filterRangeFrom}
                                            placeholder="From"
                                        />
                                    </div>
                                    <div className="col">
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="filterRangeTo"
                                            onChange={this.onChange}
                                            value={filterRangeTo}
                                            placeholder={"To"}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="form-group">
                                <label htmlFor="filterText">
                                    Filter {filterBy}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="filterText"
                                    onChange={this.onChange}
                                    value={filterText}
                                />
                            </div>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default FilterMenu;
